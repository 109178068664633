import React from "react"
import Instagram from '../images/instagram.svg';
import Facebook from '../images/facebook.svg';
import Spotify from '../images/spotify.svg';
import Apple from '../images/apple.svg';

const Footer = () => (
  <footer>
    <a href="https://www.instagram.com/daniela___pes" title="Segui Daniela Pes su Instagram" target="_blank" rel="noreferrer"><Instagram /></a>
    <a href="https://www.facebook.com/danielapes.official" title="Segui Daniela Pes su Facebook" target="_blank" rel="noreferrer"><Facebook /></a>
    <a href="https://open.spotify.com/intl-it/artist/6DEkxQm6GxNV26tUMfVf6c" title="Ascolta Daniela Pes su Spotify" target="_blank" rel="noreferrer"><Spotify /></a>
    <a href="https://music.apple.com/it/artist/daniela-pes/1388436696" title="Ascolta Daniela Pes su Apple music" target="_blank" rel="noreferrer"><Apple /></a>

    <div>
      <b>mgmt</b> damiano@panicoconcerti.com<br />
      <b>booking EU</b> matilde@panicoconcerti.com / salvo@panicoconcerti.com
    </div>
  </footer>
)

export default Footer
