import React from "react"
import { Link } from "gatsby"
import Mme from '../images/mme25.svg';

const Header = () => (
  <header>
    <h1>Daniela<br />Pes</h1>

    <menu>
      <ul>
        <li><Link to="/spira" activeClassName="active" title="Spira">spira</Link></li>
        <li><Link to="/about" activeClassName="active" title="About" partiallyActive>about</Link></li>
        <li><Link to="/tour" activeClassName="active" title="Tour">tour</Link></li>
        <li><a href="https://mmeawards.eu/en/mme_artists/vote/daniela-pes/" title="Vota Daniela Pes al Music Moves Europe Awards" target="_blank" rel="noreferrer"><Mme /></a></li>        
      </ul>
    </menu>
  </header>
)

export default Header
