exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about-en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-spira-js": () => import("./../../../src/pages/spira.js" /* webpackChunkName: "component---src-pages-spira-js" */),
  "component---src-pages-tour-archive-js": () => import("./../../../src/pages/tour/archive.js" /* webpackChunkName: "component---src-pages-tour-archive-js" */),
  "component---src-pages-tour-js": () => import("./../../../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-templates-date-js": () => import("./../../../src/templates/date.js" /* webpackChunkName: "component---src-templates-date-js" */)
}

