import React, { useState } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useMediaQuery } from 'react-responsive'

import Header from "./header"
import Footer from "./footer"

import Mute from '../images/sound-disable.svg';
import UnMute from '../images/volume-up.svg';

import videoMp4 from '../videos/video-bg-1.mp4';
import videoWebM from '../videos/video-bg-1.webm';
import videoDeskMp4 from '../videos/video-bg-desk-1.mp4';
import videoDeskWebM from '../videos/video-bg-desk-1.webm';
import audioL from '../videos/loop.mp3';
import "./layout.css"

const Layout = ({ children }) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 620px)' })
  const [ isPlaying, setIsPlaying ] = useState(false);

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Serif:opsz,wght@8..144,400;8..144,700&family=Roboto+Slab:wght@700&display=swap" rel="stylesheet" />
      </Helmet>

      <div className="main-wrapper">
        <Header />

        <main>
          {children}
        </main>

        <Footer />
      </div>

      <div className="video-bg video-bg--portrait">
        <video autoPlay muted loop playsInline>
          <source src={videoWebM} type='video/webm' />
          <source src={videoMp4} type='video/mp4' />
        </video>
      </div>
      {
        isDesktop &&
        <div className="video-bg video-bg--landscape">
          <video autoPlay muted loop playsInline>
            <source src={videoDeskWebM} type='video/webm' />
            <source src={videoDeskMp4} type='video/mp4' />
          </video>
        </div>
      }

      <div className="audio-control">
        {isPlaying ? (
          <button type="button" className="pause"
            onClick={() => setIsPlaying(false)}
            aria-label="Mute"
          >
            mute <Mute />
          </button>
        ) : (
          <button
            type="button"
            className="play"
            onClick={() => setIsPlaying(true)}
            aria-label="Unmute"
          >
            unmute <UnMute />
          </button>
        )}

        <audio src={audioL} autoPlay loop muted={!isPlaying}>
          <embed src={audioL} width="180" height="90" />
        </audio>
      </div>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
